import { render, staticRenderFns } from "./_agentsTasksTable.vue?vue&type=template&id=ef510c82"
import script from "./_agentsTasksTable.vue?vue&type=script&lang=js"
export * from "./_agentsTasksTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports